import LocationsLookupWidget from './location_lookup_widget';
import template from 'lodash/template';
import { responsiveSlick } from '../../common/carousel';
import { Address, UserLocation } from '../../types';
import formatAddress from '../../common/formatAddress';
import track from '../track';
import { getAddressFromPlace, getTimezone } from '../../common/locationUtils';
import GoogleLocations from '../../common/GoogleLocations';

interface FeaturedLocation {
    url: string;
    location_name: string;
    image_url: string;
    default_image_url: string;
    street1: string;
    city: string;
    state: string;
    zip_code: string;
    phone: string;
    location_email: string;
}

interface Options {
    authenticated: boolean;
    location: UserLocation | null;
    featured?: FeaturedLocation[];
    defaultImageUrl?: string;
    onChange: (data: { locations: FeaturedLocation[] }) => void;
    inputEl: HTMLInputElement;
}

export class LocationWidget {
    location;
    authenticated;
    dispTimezone: string | null;
    dispLocation: { lat: string; lon: string } | null;
    defaultImageUrl;
    googleLocations: any;
    onChange;

    constructor(options: Options) {
        this.location = options.location;
        this.authenticated = options.authenticated;
        this.dispTimezone = null;
        this.dispLocation = null;
        this.defaultImageUrl = options.defaultImageUrl;
        this.onChange = options.onChange;

        if (options.inputEl) {
            this.init(options.inputEl);
        }
    }

    init(inputEl: HTMLInputElement) {
        const that = this;
        this.googleLocations = new GoogleLocations({
            inputEl,
        });

        this.googleLocations.initGoogleAutocomplete(
            function (autocomplete: any, inputEl: HTMLInputElement) {
                const $el = $(inputEl),
                    address =
                        that.googleLocations.getAddressFromAutocomplete(
                            autocomplete
                        );

                that.onAddressChange($el, address);
            },
            function (results: any, status: string, inputEl: HTMLInputElement) {
                if (status === 'OK') {
                    const $el = $(inputEl),
                        address = getAddressFromPlace(results[0]);

                    that.onAddressChange($el, address);
                }
            },
            function () {
                that.clearLocation();
            }
        );
    }

    clearLocation() {
        this.dispLocation = null;
        this.dispTimezone = null;
    }

    updateLocationTime(address: UserLocation) {
        if (
            typeof address.lat === 'undefined' ||
            typeof address.lng === 'undefined'
        )
            return;

        this.dispLocation = {
            lat: address.lat.toString(),
            lon: address.lng.toString(),
        };

        getTimezone(address.lat, address.lng, ({ timeZoneId }) => {
            this.dispTimezone = timeZoneId;
        });
    }

    updateTimezone(address: Address, success: () => void) {
        getTimezone(address.lat, address.lng, success);
    }

    onAddressChange($el: JQuery, address: UserLocation) {
        $el.val(formatAddress(address));
        $el.trigger('blur');

        this.updateLocationTime(address);
        this.fetchFeatured(address).done(this.onChange);
    }

    fetchFeatured(address: UserLocation) {
        const loc = {
            lat: address.lat,
            lon: address.lng,
            ...(address.zipcode && { zip: address.zipcode }),
        };

        const url =
            '/api/v1/businesses/locations/featured/?loc=' +
            encodeURIComponent(JSON.stringify(loc));

        return $.ajax({
            method: 'GET',
            url,
        });
    }
}

export function initLocationLookupField(
    inputEl: HTMLInputElement,
    locationType: 'dispensary' | 'grower'
) {
    new LocationsLookupWidget({
        inputEl,
        locationType,
        onChange: (dispensary) => {
            if (dispensary) {
                navigateToDispDetailPage(dispensary);
            }
        },
    });
}

function navigateToDispDetailPage(dispensary: { url: string; id: number }) {
    track({
        event: 'DISP_LOOKUP',
        entity_id: dispensary.id,
    });

    if (dispensary.url) {
        window.location.href = dispensary.url;
    }
}

export function renderFeatured(
    $featuredList: JQuery,
    locations: FeaturedLocation[],
    defaultImageUrl: string
) {
    const templateFn = template($('#featured-locations-template').html());

    const html = locations
        .map((location) =>
            templateFn({
                url: location.url,
                location_name: location.location_name || 'None',
                image_url: location.image_url || 'None',
                default_image_url: defaultImageUrl || 'None',
                street1: location.street1 || 'None',
                city: location.city || 'None',
                state: location.state || 'None',
                zip_code: location.zip_code || 'None',
                phone: location.phone || 'None',
                location_email: location.location_email || 'None',
            })
        )
        .join('');

    $featuredList.html(html);

    if ($featuredList.hasClass('slick-initialized')) {
        $featuredList.removeClass(
            'slick-initialized slick-slider slick-dotted'
        );
    }

    responsiveSlick($featuredList, {
        dots: true,
        prevArrow:
            '<button class="slick-prev rounded-full bg-blue-light border-0 w-8 h-8"><i class="fa fa-caret-left"></i></button>',
        nextArrow:
            '<button class="slick-next rounded-full bg-blue-light border-0 w-8 h-8"><i class="fa fa-caret-right"></i></button>',
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: 'unslick',
            },
        ],
    });
}
