import { Variety } from '../../types';

interface Options {
    inputEl: HTMLInputElement;
    onArrowUp?: () => void;
    onArrowDown?: () => void;
    onReturn?: () => void;
    onSelect?: (selected: Selected) => void;
}

interface PayloadItem {
    id: number;
    name: string;
    variety: Variety;
    strain_slug: string;
}

interface Selected {
    id: number;
    variety: string;
    slug: string;
}

class StrainLookup {
    inputEl: HTMLInputElement;
    onArrowUp?: (
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) => void;
    onArrowDown?: (
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) => void;
    onReturn?: (
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) => void;
    onSelectCallback?: (selected: Selected) => void;

    constructor(options: Options) {
        this.inputEl = options.inputEl;
        this.onArrowUp = options.onArrowUp;
        this.onArrowDown = options.onArrowDown;
        this.onReturn = options.onReturn;
        this.onSelectCallback = options.onSelect;

        this.changeLookupInput();
        this.clickOutsidePayloadArea();
    }

    changeLookupInput() {
        const that = this;
        $(this.inputEl).on('keyup', function (e) {
            const text = $(e.currentTarget).val() as string,
                $payloadsRegion = $(e.currentTarget)
                    .parent()
                    .find('.payloads-region'),
                $activePayload = $payloadsRegion.find('.search-payload.active'),
                $firstPayload = $payloadsRegion.find(
                    '.search-payload:nth-child(1)'
                ),
                $lastPayload = $payloadsRegion.find(
                    '.search-payload:last-child'
                );

            if (e.code === 'ArrowUp' || e.key === 'ArrowUp') {
                // Up Arrow Key
                that.onArrowUpKeyPress(
                    e,
                    $activePayload,
                    $firstPayload,
                    $lastPayload
                );
                return;
            }

            if (e.code === 'ArrowDown' || e.key === 'ArrowDown') {
                // Down Arrow Key
                that.onArrowDownKeyPress(
                    e,
                    $activePayload,
                    $firstPayload,
                    $lastPayload
                );
                return;
            }

            if (e.code === 'Enter' || e.key === 'Enter') {
                // Enter Key
                $payloadsRegion.css('display', 'none');
                that.onEnterKeyPress(
                    e,
                    $activePayload,
                    $firstPayload,
                    $lastPayload
                );
                return;
            }

            if (text && text.length >= 1) {
                $.ajax({
                    method: 'GET',
                    url: '/api/v1/search/strain/lookup/?q={0}'.format(
                        encodeURIComponent(text)
                    ),
                    success: function (data) {
                        $payloadsRegion.html('');
                        const html = that.buildPayloadLookupArea(data);
                        if (!html) return;
                        $payloadsRegion.append(html);
                        $payloadsRegion.css('display', 'block');

                        $('.search-payload').on('click', function () {
                            that.onSelect($(this));
                        });
                    },
                });
            } else {
                $payloadsRegion.html('');
                $payloadsRegion.css('display', 'none');
            }
        });
    }

    onSelect($activePayload: JQuery) {
        if (!$activePayload || $activePayload.length === 0) {
            return;
        }

        const $lookupInput = $(this.inputEl),
            $payloadsRegion = $lookupInput.parent().find('.payloads-region');

        const id = $activePayload.attr('id');
        const slug = $activePayload.attr('slug');
        const variety = $activePayload.attr('variety');

        if (!id || !slug || !variety) return;

        const selected = { id: parseInt(id), slug, variety };

        $lookupInput.attr('payload-id', selected.id);
        $lookupInput.attr('payload-slug', selected.slug);
        $lookupInput.attr('payload-variety', selected.variety);
        $lookupInput.val($activePayload.text());

        $payloadsRegion.html('');

        if (this.onSelectCallback) {
            this.onSelectCallback(selected);
        }
    }

    onArrowUpKeyPress(
        e: JQuery.KeyUpEvent,
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) {
        e.preventDefault();

        if (this.onArrowUp) {
            this.onArrowUp($activePayload, $firstPayload, $lastPayload);
            return;
        }

        if ($activePayload && $activePayload.length > 0) {
            if ($activePayload.prev().length > 0) {
                $activePayload.removeClass('active');
                $activePayload.prev().addClass('active');
            }
        } else {
            $lastPayload.addClass('active');
        }
    }

    onArrowDownKeyPress(
        e: JQuery.KeyUpEvent,
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) {
        e.preventDefault();

        if (this.onArrowDown) {
            this.onArrowDown($activePayload, $firstPayload, $lastPayload);
            return;
        }

        if ($activePayload && $activePayload.length > 0) {
            if ($activePayload.next().length > 0) {
                $activePayload.removeClass('active');
                $activePayload.next().addClass('active');
            }
        } else {
            $firstPayload.addClass('active');
        }
    }

    onEnterKeyPress(
        e: JQuery.KeyUpEvent,
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) {
        e.preventDefault();

        this.onSelect($activePayload);

        if (this.onReturn) {
            this.onReturn($activePayload, $firstPayload, $lastPayload);
            return;
        }
    }

    buildPayloadLookupArea(data: { total: number; payloads: PayloadItem[] }) {
        if (data) {
            const totalResults = data.total,
                payloads = data.payloads,
                itemHtml =
                    '<span class="search-payload" id="{0}" slug="{1}" variety="{2}">{3}</span>';

            if (totalResults && totalResults > 0) {
                return payloads
                    .map((payload) =>
                        itemHtml.format(
                            payload.id,
                            payload.strain_slug,
                            payload.variety,
                            payload.name
                        )
                    )
                    .join('');
            }
        }
    }

    clickOutsidePayloadArea() {
        $(document).on('click', function (e) {
            var elem = $(e.target);
            if (!elem.parents('.payloads-region').length) {
                $('.payloads-region').html('');
                $('.payloads-region').css('display', 'none');
            }
        });
    }
}

export default StrainLookup;
