function TypeWriter() {
    var $elem = $('.typewriter');
    var words = ['Relax', 'Relieve Pain', 'Focus', 'Sleep', 'Energize'];
    var wordsN = words.length;
    var wordsIndex = 0;
    var letterIndex = 0;

    function iterate() {
        var delay = 100;
        var word = words[wordsIndex].slice(0, letterIndex);
        $elem.html(word);
        if (letterIndex < words[wordsIndex].length) {
            letterIndex++;
        } else {
            letterIndex = 0;
            wordsIndex++;
            delay = 2500;
            if (wordsIndex >= wordsN) {
                wordsIndex = 0;
            }
        }
        setTimeout(iterate, delay);
    }
    iterate();
}

export default TypeWriter;
