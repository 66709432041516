import template from 'lodash/template';
import TypeWriter from '../common/typewriter';
import {
    initLocationLookupField,
    LocationWidget,
} from './locations/locations_list_utils';
import { PageOptions } from '../types';
import StrainLookup from './strain/strain_lookup';
import LocationView from '../views/LocationView';

class HomePage {
    location;
    authenticated;
    userId;

    constructor(options: PageOptions) {
        this.location = options && options.location;
        this.authenticated = options && options.authenticated;
        this.userId = options && options.userId;

        this.init();
    }

    init() {
        this.initStrainLookupField();

        TypeWriter();

        const dispLookupEl = document.getElementById(
            'dispensary-lookup'
        ) as HTMLInputElement;
        const dispLocWidgetEl = document.getElementById(
            'dispensary-filter'
        ) as HTMLInputElement;

        if (dispLocWidgetEl) {
            new LocationWidget({
                inputEl: dispLocWidgetEl,
                location: this.location,
                authenticated: this.authenticated,
                onChange: () => {},
            });
        }
        if (dispLookupEl) {
            initLocationLookupField(dispLookupEl, 'dispensary');
        }

        const growerLookupEl = document.getElementById(
            'location-lookup'
        ) as HTMLInputElement;

        if (growerLookupEl) {
            initLocationLookupField(growerLookupEl, 'grower');
        }

        const growerLocWidgetEl = document.getElementById(
            'location-filter'
        ) as HTMLInputElement;

        if (growerLocWidgetEl) {
            new LocationWidget({
                inputEl: growerLocWidgetEl,
                location: this.location,
                authenticated: this.authenticated,
                onChange: () => {},
            });
        }
    }

    initStrainLookupField() {
        const lookupTemplate = template($('#strain-lookup-field').html());
        $('.home .strain-name-field').html(
            lookupTemplate({
                lookup_placeholder:
                    'Blue Dream, Maui Wowie, Pineapple express...',
            })
        );
        // TODO: this should be refactored to only work on a specific element
        const inputEl = document.querySelector(
            '.home .strain-lookup-input'
        ) as HTMLInputElement;
        if (inputEl) {
            new StrainLookup({
                inputEl,
                onSelect: this.navigateToStrainDetailPage,
            });
        }
    }

    navigateToStrainDetailPage(selected: { variety: string; slug: string }) {
        if (selected.variety && selected.slug) {
            window.location.href = `/strains/${selected.variety}/${selected.slug}/`;
        }
    }
}

new HomePage(pageOptions);
new LocationView(pageOptions);
